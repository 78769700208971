<template>
    <div class="page formPage" id="releaseActivities">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="query-form">
            <el-form-item>
                <template>
                    <el-radio @change="typeSwitching" v-model="ruleForm.actiType" label="0">活动</el-radio>
                    <el-radio @change="typeSwitching" v-model="ruleForm.actiType" label="1">讲座</el-radio>
                </template>
            </el-form-item>
            <div class="biaodan">
                <div class="left">
                    <div class="xiao">
                        <el-form-item v-if="ruleForm.actiType != '2'" label="活动名称" prop="name">
                            <el-col :span="12">
                                <el-input size="small" clearable v-model="ruleForm.name"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-else label="直播名称" prop="broadcastName">
                            <el-input size="small" clearable v-model="ruleForm.broadcastName"></el-input>
                        </el-form-item>
                        <el-form-item label="活动类别" prop="actiServiceObj">
                            <template>
                                <el-radio v-model="ruleForm.actiServiceObj" label="0">个人活动</el-radio>
                                <el-radio v-model="ruleForm.actiServiceObj" label="1">团体活动</el-radio>
                                <el-radio v-model="ruleForm.actiServiceObj" label="2">亲子活动</el-radio>
                            </template>
                        </el-form-item>
                        <el-form-item label="活动系列" size="small">
                            <el-button type="primary" @click="popup(1)">选择系列</el-button>
                            <div class="xiliebiaoqian" v-if="ruleForm.series">
                                <div>{{ `系列名：${ruleForm.series.name}`}}</div>
                                <div>{{`系列时段：${ruleForm.series.startTime} 至 ${ruleForm.series.endTime}` }}<i
                                        @click="seriesDeletion(1)" class="el-icon-circle-close"></i></div>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.actiType != '2'" label="活动地点" prop="address">
                            <el-col :span="12">
                                <el-input size="small" maxlength="90" show-word-limit
                                          v-model="ruleForm.address"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="活动标签">
                            <el-button size="small" type="primary" @click="popup(2)">选择标签</el-button>
                            <div class="xiliebiaoqian" v-if="ruleForm.activityLabel">
                                {{ `活动标签：${ruleForm.activityLabel.name}`}}
                                <i @click="seriesDeletion(2)" class="el-icon-circle-close"></i>
                            </div>
                        </el-form-item>
                        <el-form-item label="活动图片" prop="imgUrl">
                            <ImgUpload :imgAskObj="uploadRequire"
                                       @getImgList="getImgList"
                            ></ImgUpload>
                        </el-form-item>
                    </div>
                </div>
                <div class="left">
                    <div class="xiao">
                        <el-form-item label="置顶" prop="top">
                            <el-input-number size="small" @change="handleChangeTop()" v-model="ruleForm.top" :min="1"
                                             :max="10"
                                             label="描述文字"></el-input-number>
                            <!--                            <el-switch v-model="ruleForm.top"-->
                            <!--                                       :active-value="'1'"-->
                            <!--                                       :inactive-value="'0'"-->
                            <!--                            ></el-switch>-->
                        </el-form-item>
                        <!--                        <el-form-item label="是否微信消息推送">-->
                        <!--                            <template>-->
                        <!--                                <el-radio v-model="ruleForm.gzhPush" label="1">是</el-radio>-->
                        <!--                                <el-radio v-model="ruleForm.gzhPush" label="0">否</el-radio>-->
                        <!--                            </template>-->
                        <!--                        </el-form-item>-->
                        <el-form-item label="是否允许报名">
                            <template>
                                <el-radio v-model="ruleForm.isApply" label="1">是</el-radio>
                                <el-radio v-model="ruleForm.isApply" label="0">否</el-radio>
                            </template>
                        </el-form-item>
                        <el-form-item label="一键发布">
                            <el-checkbox true-label="1" false-label="0" v-model="ruleForm.officialWebsitePush">官网
                            </el-checkbox>
                            <el-checkbox true-label="1" false-label="0" v-model="ruleForm.hfivePush">H5</el-checkbox>
                            <!--                            <el-checkbox true-label="1" false-label="0" v-model="ruleForm.gzhPush">公众号</el-checkbox>-->
                        </el-form-item>
                        <el-form-item label="地址采集">
                            <el-radio-group v-model="ruleForm.isAddress">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.actiServiceObj != 1" label="作品收集">
                            <template>
                                <el-radio v-model="ruleForm.productionPush" label="1">是</el-radio>
                                <el-radio v-model="ruleForm.productionPush" label="0">否</el-radio>
                            </template>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.actiType == '2'" label="是否公开">
                            <template>
                                <el-radio v-model="ruleForm.open" label="1">是</el-radio>
                                <el-radio v-model="ruleForm.open" label="0">否</el-radio>
                            </template>
                        </el-form-item>
                        <el-form-item label="报名方式">
                            <el-checkbox-group @change="afudaubdfn" v-model="ruleForm.cardType">
                                <el-checkbox v-for="item in allCertificates" :key="item.id" :label="item.id" name="type"
                                             :disabled="item.cardTypeName == '身份证'">{{ item.cardTypeName }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <!--            <div v-if="ruleForm.gzhPush ==1">-->
            <!--                <div style="text-align: center">消息模板</div>-->
            <!--                <div class="biaodan">-->
            <!--                    <div class="left">-->
            <!--                        <el-form-item label="标题" prop="baioti">-->
            <!--                            <el-col :span="16">-->
            <!--                                <el-input disabled size="small" clearable v-model="ruleForm.baioti"></el-input>-->
            <!--                            </el-col>-->
            <!--                        </el-form-item>-->
            <!--                        <el-form-item label="提示语" prop="wxMesMb.first"-->
            <!--                                      :rules="[{required: true, message: '名称不能为空', trigger: 'blur'}]">-->
            <!--                            <el-col :span="16">-->
            <!--                                <el-input size="small" placeholder="有新的活动发布啦,快来看看吧" clearable-->
            <!--                                          v-model="ruleForm.wxMesMb.first"></el-input>-->
            <!--                            </el-col>-->
            <!--                        </el-form-item>-->
            <!--                        <el-form-item label="待办事项" prop="wxMesMb.keyword1">-->
            <!--                            <el-col :span="16">-->
            <!--                                <el-input size="small" placeholder="建议填写活动名称" clearable-->
            <!--                                          v-model="ruleForm.wxMesMb.keyword1"></el-input>-->
            <!--                            </el-col>-->
            <!--                        </el-form-item>-->
            <!--                        <el-form-item label="时间" prop="wxMesMb.keyword2">-->
            <!--                            <el-col :span="16">-->
            <!--                                <el-input size="small" placeholder="建议填写时间" clearable-->
            <!--                                          v-model="ruleForm.wxMesMb.keyword2"></el-input>-->
            <!--                            </el-col>-->
            <!--                        </el-form-item>-->
            <!--                        <el-form-item label="备注" prop="remark">-->
            <!--                            <el-col :span="16">-->
            <!--                                <el-input size="small" type="textarea" resize="none"-->
            <!--                                          :rows="4" clearable v-model="ruleForm.wxMesMb.remark"></el-input>-->
            <!--                            </el-col>-->
            <!--                        </el-form-item>-->
            <!--                    </div>-->
            <!--                    <div class="right" style="width: 50%">-->
            <!--                        <div class="xiaokaui">-->
            <!--                            <div>标题：{{ ruleForm.baioti }}</div>-->
            <!--                            <div class="jabdfhj">{{ ruleForm.wxMesMb.first }}</div>-->
            <!--                            <div class="jabdfhj tuoig">待办事项：{{ ruleForm.wxMesMb.keyword1 }}</div>-->
            <!--                            <div class="jabdfhj tuoig">时间：{{ ruleForm.wxMesMb.keyword2 }}</div>-->
            <!--                            <div class="jabdfhj">{{ ruleForm.wxMesMb.remark }}</div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="data">
                <div :class="[index == 0 ? 'list2' : 'list']" v-for="(item,index) in ruleForm.activityCourseSession"
                     :key="index">
                    <el-row :gutter="10">
                        <el-col :span="13">
                            <el-form-item label="活动时间" :prop="`activityCourseSession.${index}.actuvutyTotall`"
                                          :rules="[{ required: true, message: '请输入活动时间', trigger: 'blur' }]">
                                <el-date-picker
                                        style="width: 97%"
                                        size="small"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.actuvutyTotall"
                                        @change="activeTimePeriod(index)"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="13">
                            <el-form-item label="预约时间" :prop="`activityCourseSession.${index}.appointmentPeriod`"
                                          :rules="[{ required: true, message: '请输入预约时间', trigger: 'blur' }]">
                                <el-date-picker
                                        style="width: 97%"
                                        size="small"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.appointmentPeriod"
                                        @change="activeappointmentPeriod(index)"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-form-item label-width="0" required>
                            <el-col :span="7">
                                <el-form-item label="退票截止时间" :prop="`activityCourseSession.${index}.outTicketTime`"
                                              :rules="[{ required: true, message: '请输入退票截止时间', trigger: 'blur' }]">
                                    <el-date-picker
                                            style="width: 95%"
                                            size="small"
                                            v-model="item.outTicketTime"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            type="datetime"
                                            placeholder="退票截止时间">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="17">
                                <el-col :span="8">
                                    <el-form-item clearable label="最少人数"
                                                  :prop="`activityCourseSession.${index}.minPeople`"
                                                  :rules="[{ required: true, message: '最少人数', trigger: 'blur' },
                                                  { pattern: /^[0-9]*[1-9][0-9]*$/, message: '最少人数必须为整数'}]">
                                        <el-input size="small" min="0" style="width: 100%"
                                                  v-model="item.minPeople"
                                                  onpaste="return false;"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item clearable label="最大人数"
                                                  :prop="`activityCourseSession.${index}.maxPeople`"
                                                  :rules="[{ required: true, message: '最大人数', trigger: 'blur' },
                                                  { pattern: /^[0-9]*[1-9][0-9]*$/, message: '最大人数必须为整数'}]">
                                        <el-input size="small" min="0" style="width: 100%"
                                                  v-model="item.maxPeople"
                                                  onpaste="return false;"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="内部名额"
                                                  :prop="`activityCourseSession.${index}.reservePeople`"
                                                  :rules="[{ required: true, message: '内部名额', trigger: 'blur' },
                                                  { pattern: /^[0-9]*[0-9][0-9]*$/, message: '内部名额必须为整数'}]">
                                        <el-input clearable size="small" min="0" style="width: 100%"
                                                  v-model="item.reservePeople"
                                                  onpaste="return false;"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-form-item>
                    </el-row>
                    <div v-if="index != 0 && activityselect != 2  && activityselect != 3"
                         @click="deleteTimePeriod(index)" class="shanchuanniu">×
                    </div>
                </div>
            </div>
            <div class="addanniu">
                <el-button v-if="ruleForm.actiType != '2' && activityselect != 2  && activityselect != 3"
                           @click="addTimePeriod" type="primary" size="small"
                           icon="el-icon-plus">增加时间段
                </el-button>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="收费类型" required>
                        <el-col :span="9">
                            <el-form-item prop="chargeType1">
                                <el-select :disabled="guanbishoufei" size="small" v-model="ruleForm.chargeType"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item prop="price">
                                <el-input clearable size="small" min="'0'" v-model="ruleForm.price"
                                          placeholder="价格"
                                          :disabled="shoufeileixin"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <div style="white-space: nowrap;">元/人</div>
                        </el-col>
                    </el-form-item>
                </el-col>
                <el-col :span="3">
                    <el-form-item label="促销">
                        <!--                        <el-switch v-model="ruleForm.isPromotion"-->
                        <el-switch v-model="switchxuans"
                                   :disabled="shoufeileixin"
                                   @change="PromotionStatusChange"
                                   :active-value="'1'"
                                   :inactive-value="'0'"
                        ></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
            <!--                <el-col :span="1">-->
            <!--                    <el-checkbox true-label="1" false-label="0" v-model="ruleForm.isPromotion"><div>促销价</div></el-checkbox>-->
            <!--                </el-col>-->
            <el-row :gutter="20" v-if="ruleForm.isPromotion != 0 && ruleForm.chargeType != 0">
                <el-col :span="12">
                    <el-form-item label="促销价格" required>
                        <el-col :span="9">
                            <el-form-item prop="promotionPrice">
                                <el-input clearable size="small" min="0" v-model="ruleForm.promotionPrice"
                                          placeholder="价格"
                                          :disabled="shoufeileixin"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <div style="white-space: nowrap; line-height: 40px">元/人</div>
                        </el-col>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="促销时间" prop="promotionPeriod"
                                  :rules="[{ required: true, message: '请选择促销时间', trigger: 'change' }]">
                        <el-date-picker
                                style="width: 100%"
                                size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                :disabled="shoufeileixin"
                                v-model="ruleForm.promotionPeriod"
                                @change="activePromotionPeriod"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="服务对象" required>
                        <el-col :span="4">
                            <el-form-item prop="ageObject">
                                <el-select size="small" v-model="ruleForm.ageObject" placeholder="请选择">
                                    <el-option
                                            v-for="item in service"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item prop="minAge">
                                <el-input maxlength="3" show-word-limit clearable size="small" min="0"
                                          v-model="ruleForm.minAge"
                                          placeholder="年龄"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <div style="white-space: nowrap;">岁（含）以上</div>
                        </el-col>
                        <el-col :span="4" :offset="2">
                            <el-form-item prop="maxAge">
                                <el-input maxlength="3" show-word-limit clearable style="width: 100%" size="small"
                                          min="0" v-model="ruleForm.maxAge"
                                          placeholder="年龄"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <div style="white-space: nowrap;">岁（含）以下</div>
                        </el-col>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="报名须知" prop="applyNotice">
                    <Editor :editortext="ruleForm.applyNotice" ref="editor" @changeHtml="noticeGetEditor"></Editor>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="活动内容" prop="actiContent">
                    <Editor :editortext="ruleForm.actiContent" ref="editor" @changeHtml="contentGetEditor"></Editor>
                </el-form-item>
            </el-row>

            <div class="textBox" v-if="activityselect==0">
                <!--查看页面-->
                <el-button v-if="hasPermission('activity:releaseActivities:adopt')" type="primary"
                           size="small" @click="reject(1)">通过
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:reject')" type="danger"
                           size="small" @click="reject(2)">驳回
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:preview') && editOrNot==0" type="primary"
                           size="small" @click="preview(0)">预览
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                           type="primary"
                           size="small" @click="preview(1,actiState)">保存并预览
                </el-button>
                <el-button type="primary" size="small" @click="reject(0)">返回</el-button>
            </div>

            <div class="textBox" v-if="activityselect==2">
                <!--查看页面-->
                <!--                <el-button v-if="hasPermission('activity:releaseActivities:adopt')" type="primary"-->
                <!--                           size="small" @click="reject(3)">发布-->
                <!--                </el-button>-->
                <el-button v-if="hasPermission('activity:releaseActivities:preview') && editOrNot==0" type="primary"
                           size="small" @click="preview(0)">预览
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                           type="primary"
                           size="small" @click="preview(1,actiState)">保存并预览
                </el-button>
                <el-button type="primary" size="small" @click="reject(0)">返回</el-button>
            </div>

            <div class="textBox" v-if="activityselect==3">
                <!--查看页面-->
                <el-button v-if="hasPermission('activity:releaseActivities:preview')&& editOrNot==0" type="primary"
                           size="small" @click="preview(0)">预览
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                           type="primary"
                           size="small" @click="preview(1,actiState)">保存并预览
                </el-button>
                <el-button type="primary" size="small" @click="reject(0)">返回</el-button>
            </div>

            <!-- 发布活动-->
            <div class="textBox" v-if="activityselect==1">
                <el-button v-if="hasPermission('activity:releaseActivities:submit')" type="primary"
                           size="small" @click="reject(4)">提交
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview')" type="primary"
                           size="small" @click="preview(1)">保存草稿并预览
                </el-button>
                <el-button type="primary" size="small" @click="reject(0)">返回</el-button>
            </div>

            <!-- 发布活动-->
            <div class="textBox" v-if="activityselect==4">
                <el-button v-if="hasPermission('activity:releaseActivities:release') && editOrNot==0" type="primary"
                           size="small" @click="reject(3)">发布
                </el-button>
                <el-button v-if="hasPermission('activity:releaseActivities:draftAndPreview') && editOrNot==1"
                           type="primary"
                           size="small" @click="preview(1,actiState)">保存并预览
                </el-button>
                <el-button type="primary" size="small" @click="reject(0)">返回</el-button>
            </div>


            <seriesLabelPop @select="select" @closed="closed" :series="series" :seriesActivities="seriesActivities"
                            :choice="choice"></seriesLabelPop>

            <!--            预览弹窗-->
            <el-dialog
                    title="预览二维码"
                    :visible.sync="previewQrCode"
                    width="40%"
                    :close-on-click-modal="tanchuan"
                    :before-close="previewQrCodeHandleClose">
                <div style="text-align: center">
                    <img :src="qrcode">
                </div>
                <!--                <div>预览链接：</div>-->
                <div style="text-align: center">
                    <el-button @click="previewQrCodeGuanbi" size="small" type="primary">关闭</el-button>
                </div>
            </el-dialog>

        </el-form>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件
    import ImgUpload from "@/components/imgUpload/imgUpload"; // 导入图片上传组件
    import seriesLabelPop from './seriesLabelPop'; //系列活动，活动标签弹窗
    export default {
        name: "releaseActivities",
        components: {Editor, ImgUpload, seriesLabelPop},
        data() {
            return {
                switchxuans: '0',
                qrcode: '',//二维码地址
                editOrNot: '',//是否编辑
                actiState: '',//当前活动状态
                tanchuan: false,
                activityselect: 1,//0,1,2,3
                ruleForm: {
                    actiType: '0',
                    name: '',  //活动名称
                    broadcastName: '', //直播名称 ---
                    actiServiceObj: '', //活动类别
                    series: '', //系列活动 ---
                    activityLabel: '',//活动标签 ---
                    address: '', //活动地点
                    imgUrl: '',
                    top: '0', //置顶
                    officialWebsitePush: '1', //推送至官网
                    hfivePush: '1', //推送至H5
                    // gzhPush: '0', //是否微信消息推送
                    isAddress: '0', //地址采集 --
                    productionPush: '0', //作品收集
                    isApply: '1',//是否允许报名
                    chargeType: '0', //收费类型
                    price: 0, //价格
                    isPromotion: '0', //是否开启促销
                    promotionPrice: '', //促销价
                    promotionPeriod: '',//促销时段
                    startPromotion: '', //促销时间
                    endtPromotion: '',
                    ageObject: '', //服务对象
                    minAge: '', //最小年龄
                    maxAge: '', //最大年龄
                    applyNotice: '', //报名须知
                    actiContent: '', //活动内容
                    seriesId: '',//系列id
                    labelId: '',//标签id
                    open: '', //是否公开
                    cardType: [],//报名方式

                    activityCourseSession: [ //活动时间段
                        {
                            actuvutyTotall: '',//总时间
                            startTime: '', //开始时间
                            endTime: '',   //结束时间
                            appointmentPeriod: '',//预约时段
                            ppointmentStartTime: '', //预约开始时间
                            ppointmentEndTime: '', //预约截止时间
                            outTicketTime: '',  //退票截止时间
                            minPeople: '',  //最少人数
                            maxPeople: '', //最多人数
                            reservePeople: 0,//内部预留名额
                        },
                    ],
                },
                rules: {
                    name: [{
                        required: true, message: '请输入活动名称', trigger: 'blur'
                    }],
                    broadcastName: [{
                        required: true, message: '请输入活动名称', trigger: 'blur'
                    }],
                    actiServiceObj: [
                        {required: true, message: '请选择活动类别', trigger: 'change'}
                    ],
                    series: [{
                        required: true, message: '请选择活动系列', trigger: 'change'
                    }],
                    address: [{
                        required: true, message: '请输入活动地点', trigger: 'blur'
                    }],
                    imgUrl: [{
                        required: true, message: '请上传活动图片', trigger: 'blur'
                    }],
                    chargeType: [{
                        required: true, message: '请选择收费类型', trigger: 'blur'
                    }],
                    price: [
                        {
                            required: true, message: '请输入价格', trigger: 'blur'
                        },
                        {
                            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '请输入正确的价格'
                        },
                        // {
                        //     validator: (rule, value, callback) => {
                        //         if (value<0.01){
                        //             callback(new Error("价格不能小于0.01"))
                        //         }else {
                        //             callback()
                        //         }
                        //     },
                        //     trigger: ['blur', 'change']
                        // }
                    ],
                    promotionPrice: [
                        {
                            required: true, message: '请输入价格', trigger: 'blur'
                        },
                        {
                            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '请输入正确的价格'
                        },
                        // {
                        //     validator: (rule, value, callback) => {
                        //         if (value<0.01){
                        //             callback(new Error("价格不能小于0.01"))
                        //         }else {
                        //             callback()
                        //         }
                        //     },
                        //     trigger: ['blur', 'change']
                        // }
                    ],
                    endtPromotion: [{
                        required: true, message: '请选择结束时间段', trigger: 'change'
                    }],
                    ageObject: [{
                        required: true, message: '选择服务对象', trigger: 'change'
                    }],
                    minAge: [{
                        required: true, message: '最小年龄', trigger: 'blur'
                    },
                        {pattern: /^[0-9]*[1-9][0-9]*$/, message: '最小年龄必须为整数'}],
                    maxAge: [{
                        required: true, message: '最大年龄', trigger: 'blur'
                    },
                        {pattern: /^[0-9]*[1-9][0-9]*$/, message: '最大年龄必须为整数'}],
                    applyNotice: [{
                        required: true, message: '请输入报名须知', trigger: 'blur'
                    }],
                    actiContent: [{
                        required: true, message: '请输入活动内容', trigger: 'blur'
                    }],
                },
                options: [ //收费类型选择
                    {
                        value: '1',
                        label: '收费'
                    }, {
                        value: '0',
                        label: '免费'
                    },],
                service: [ //服务对象选择
                    {
                        value: '2',
                        label: '成人'
                    },
                    {
                        value: '0',
                        label: '亲子'
                    },
                    {
                        value: '1',
                        label: '青少年'
                    },
                ],
                uploadRequire: {
                    //上传图片要求
                    imgCount: 1, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course"
                },
                id: '',//修改活动的id

                //弹窗
                seriesActivities: false,//系列活动、标签管理的弹窗
                series: 0,// 1为活动系列、2为活动标签弹窗
                choice: 1,

                //预览二维码
                previewQrCode: false,
                //免费时禁用价格
                shoufeileixin: false,
                guanbishoufei: false,

                allCertificates: [],
            }
        },
        watch: {
            $route: {
                handler() {
                    if (this.$route.query.id) {
                        this.id = this.$route.query.id
                        this.getActivityDetails(this.id)
                        this.editOrNot = this.$route.query.num; //0查看 1编辑
                        this.actiState = this.$route.query.actiState;
                        console.log(this.actiState)
                        if (this.actiState == '0') { //草稿
                            this.activityselect = 1;
                        } else if (this.actiState == '1') { //未开始
                            this.activityselect = 2
                        } else if (this.actiState == '3' || this.actiState == '2') { //已结束 进行中
                            this.activityselect = 3;
                        } else if (this.actiState == '6') { //待发布
                            this.activityselect = 4;
                        } else if (this.actiState == '5') { //评审中
                            this.activityselect = 0;
                        }
                    }
                },
                deep: true,
                immediate: true
            },
            'ruleForm.chargeType': {
                handler() {
                    if (this.ruleForm.chargeType == 0) {
                        this.switchxuans = 0;
                        this.ruleForm.isPromotion = '0';
                        this.ruleForm.price = 0;
                        this.ruleForm.promotionPrice = '';
                        this.ruleForm.promotionPeriod = '';
                        this.ruleForm.startPromotion = '';
                        this.ruleForm.endtPromotion = '';
                        this.shoufeileixin = true;
                    } else {
                        this.shoufeileixin = false;
                        // this.ruleForm.isPromotion = 0;
                    }
                },
                deep: true,
                immediate: true
            },
            'ruleForm.productionPush': {
                handler() {
                    if (this.ruleForm.productionPush == 1) {
                        this.ruleForm.chargeType = '0';
                        this.ruleForm.isPromotion = 0;
                        this.ruleForm.price = 0;
                        this.ruleForm.promotionPrice = '';
                        this.ruleForm.promotionPeriod = '';
                        this.ruleForm.startPromotion = '';
                        this.ruleForm.endtPromotion = '';
                        this.guanbishoufei = true;
                    } else {
                        this.guanbishoufei = false;
                    }
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.obtainCertificates();
        },
        methods: {
            // //查询消息模板
            // xiaoximoban(id) {
            //     // this.ruleForm.baioti = '待办事项通知';
            //     this.$axios(this.api.messge.mesage, {actId: id, type: 0}, 'get').then(res => {
            //         if (res.data) {
            //             this.ruleForm.baioti = '待办事项通知';
            //             this.ruleForm.wxMesMb = res.data;
            //         }
            //         // this.ruleForm.wxMesMb.first = res.data.first;
            //         // this.ruleForm.wxMesMb.keyword1 = res.data.keyword1;
            //         // this.ruleForm.wxMesMb.keyword2 = res.data.keyword2;
            //         // this.ruleForm.wxMesMb.remark = res.data.remark;
            //     })
            // },

            //活动讲座的切换
            typeSwitching(label) {
                if (label == 0) {
                    this.ruleForm.address = '湖南博物院'
                } else {
                    this.ruleForm.address = '湖南博物院一楼学术报告厅'
                }
            },

            //获取能选择的所有证件
            obtainCertificates() {
                this.$axios(this.api.allCommon.all, {}, 'get').then((res) => {
                    if (res.status) {
                        this.allCertificates = res.data;
                        this.findActivityCardTypeById(this.id);
                    }
                })
            },

            afudaubdfn() {
                console.log(this.ruleForm.cardType)
            },

            //查看该活动选中的证件
            findActivityCardTypeById(id) {
                this.$axios(this.api.allCommon.findActivityCardTypeById, {activityId: id}, 'get').then((res) => {
                    if (res.status) {
                        if (res.data.length == 0) {
                            this.allCertificates.forEach(item => {
                                if (item.cardTypeName == '身份证') {
                                    this.ruleForm.cardType.push(item.id);
                                }
                            })
                        }
                    }
                })
            },


            //判断预约时段在不在系列时段中
            judgmentPeriod() {
                if (this.ruleForm.series.length != 0) {
                    this.ruleForm.activityCourseSession.map(item => {
                        if (item.appointmentPeriod) {
                            if (this.timeRatioSize(this.ruleForm.series.startTime, item.appointmentPeriod[0]) && this.timeRatioSize(item.appointmentPeriod[1], this.ruleForm.series.endTime)) {
                                console.log('在时间段')
                            } else {
                                this.$message.warning('场次预约时间不在活动系列时间内')
                            }
                        }
                    })
                }
            },

            //促销状态改变
            PromotionStatusChange() {
                if (this.switchxuans == 0) {
                    this.ruleForm.promotionPrice = '';
                    this.ruleForm.promotionPeriod = '';
                    this.ruleForm.startPromotion = '';
                    this.ruleForm.endtPromotion = '';
                    this.ruleForm.isPromotion = '0';
                } else {
                    this.ruleForm.isPromotion = '1';
                }
            },

            //判断两个时间的大小
            timeRatioSize(date, date2) {
                var date = new Date(date);
                var date1 = new Date(date2);
                if (date.getTime() - date1.getTime() <= 0) {
                    return true;
                } else
                    return false;
            },

            //活动时间段选中事件
            activeTimePeriod(num) {
                this.ruleForm.activityCourseSession[num].startTime = this.ruleForm.activityCourseSession[num].actuvutyTotall[0]
                this.ruleForm.activityCourseSession[num].endTime = this.ruleForm.activityCourseSession[num].actuvutyTotall[1]
            },

            //预约时段选中
            activeappointmentPeriod(num) {
                if (this.ruleForm.seriesId) {
                    this.judgmentPeriod();
                }
                this.ruleForm.activityCourseSession[num].ppointmentStartTime = this.ruleForm.activityCourseSession[num].appointmentPeriod[0]
                this.ruleForm.activityCourseSession[num].ppointmentEndTime = this.ruleForm.activityCourseSession[num].appointmentPeriod[1]
            },

            //促销时段选择
            activePromotionPeriod(item) {
                this.ruleForm.startPromotion = item[0];
                this.ruleForm.endtPromotion = item[1];
            },

            //获取活动详情
            getActivityDetails(id) {
                this.$axios(this.api.activity.activityDetails + id, {}, 'get').then((res) => {
                    if (res.status) {
                        this.ruleForm = this.recover(this.ruleForm, res.data);
                        // Object.assign(this.ruleForm, res.data)
                        this.ruleForm.activityCourseSession = res.data.activityCourse[0].activityCourseSession;
                        this.ruleForm.activityCourseSession.map(item => {
                            this.$set(item, 'actuvutyTotall', [item.startTime, item.endTime]);
                            this.$set(item, 'appointmentPeriod', [item.ppointmentStartTime, item.ppointmentEndTime]);
                        })
                        this.ruleForm.isPromotion = res.data.isPromotion;
                        if (this.ruleForm.startPromotion) {
                            this.ruleForm.promotionPeriod = [this.ruleForm.startPromotion, this.ruleForm.endtPromotion];
                        }
                        console.log(this.ruleForm.promotionPeriod)
                        this.uploadRequire.imgList.push({name: '图片', url: res.data.imgUrl});
                        this.ruleForm.imgUrl = res.data.imgUrl;
                        if (this.ruleForm.isPromotion == 2 || this.ruleForm.isPromotion == 3 || this.ruleForm.isPromotion == 1) {
                            this.switchxuans = '1';
                        }
                        this.getContent(id)
                        this.xiaoximoban(id)
                    }
                })
            },

            //获取标签和系列内容
            getContent(id) {
                this.$axios(this.api.activity.getContent, {id: id}, 'get').then(res => {
                    if (res.status) {
                        if (res.data.series) {
                            this.ruleForm.series = res.data.series;
                            this.ruleForm.seriesId = res.data.series.id;
                            console.log(this.ruleForm.seriesId)
                        }
                        if (res.data.label) {
                            this.ruleForm.labelId = res.data.label.id;
                            this.ruleForm.activityLabel = res.data.label;
                            console.log(this.ruleForm.labelId)
                        }
                    }
                })
            },

            //打开弹窗标题 1为活动系列、2为活动标签弹窗
            popup(num) {
                this.series = num;
                this.seriesActivities = true;
            },

            //关闭弹窗
            closed() {
                this.seriesActivities = false;
            },

            //弹窗选择
            select(row) {
                if (this.series == 1) {
                    this.ruleForm.series = row;
                    this.ruleForm.seriesId = row.id;
                    if (this.ruleForm.activityCourseSession[0].appointmentPeriod[0]) {
                        this.judgmentPeriod();
                    }
                } else if (this.series == 2) {
                    this.ruleForm.activityLabel = row;
                    this.ruleForm.labelId = row.id;
                }
            },

            // 获取上传图片数据
            getImgList(data) {
                if (data.length == 0) {
                    this.ruleForm.imgUrl = ""
                } else {
                    this.ruleForm.imgUrl = data[0].imgUrl
                }
            },

            //删除活动序列
            seriesDeletion(num) {
                if (num == 1) {
                    this.ruleForm.series = '';
                    this.ruleForm.seriesId = '';
                } else {
                    this.ruleForm.activityLabel = '';
                    this.ruleForm.labelId = '';
                }
            },

            // 获取富文本数据(报名须知)
            noticeGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.applyNotice = data;
            },

            // 获取富文本数据(活动内容)
            contentGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.actiContent = data;
            },

            //预览二维码弹窗
            previewQrCodeHandleClose(done) {
                this.previewQrCode = false;
            },

            //按钮区域
            //增加时间段
            addTimePeriod() {
                const timePeriod = {
                    actuvutyTotall: '',//总时间
                    startTime: '',
                    endTime: '',
                    appointmentPeriod: '',//预约时段
                    ppointmentStartTime: '',
                    ppointmentEndTime: '',
                    outTicketTime: '',
                    minPeople: '',
                    maxPeople: '',
                    reservePeople: 0,
                };
                this.ruleForm.activityCourseSession.push(timePeriod);
            },

            //删除时间段
            deleteTimePeriod(index) {
                this.$confirm(`确定删除所选项吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.ruleForm.activityCourseSession.splice(index, 1);
                });
            },

            // 触发表单验证(num1为提交，2为保存草稿） ,
            submitForm(formName, num, actState) {
                if (this.ruleForm.chargeType == 1 && this.ruleForm.price < 0.01){
                    this.$message.error('收费价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice < 0.01){
                    this.$message.error('促销价格不得低于0.01元')
                    return;
                }
                if (this.ruleForm.activityCourseSession.length == 0) {
                    this.$message.success('活动场次信息为空，请创建活动场次')
                } else {
                    let actiState = {};
                    if (num == 1) {
                        actiState = '5';
                    } else {
                        actiState = actState;
                    }
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.ruleForm.maxAge = parseInt(this.ruleForm.maxAge);
                            this.ruleForm.minAge = parseInt(this.ruleForm.minAge);
                            this.ruleForm.activityCourseSession.map((item) => {
                                item.minPeople = parseInt(item.minPeople);
                                item.maxPeople = parseInt(item.maxPeople);
                                if (item.reservePeople == '') {
                                    item.reservePeople = 0;
                                }
                                // item.reservePeople = parseInt(item.reservePeople);
                            })
                            if (this.id == '') { //新增活动
                                this.$axios(this.api.activity.newActivities, {
                                    actiState,
                                    ...this.ruleForm
                                }, 'post').then((res) => {
                                    if (res.status) {
                                        this.$message.success('新增活动成功')
                                        if (num != 2) {
                                            this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                                            this.$router.push({path: '/activity/activityList'})
                                        } else {
                                            this.qrcode = 'data:image/png;base64,' + res.data.qrCode;
                                            this.id = res.data.id;
                                            this.previewQrCode = true;
                                        }
                                    }
                                })
                            } else { //修改活动
                                if (this.activityselect != 2 && this.activityselect != 3) {
                                    this.$axios(this.api.activity.modifyActivity, {
                                        actiState,
                                        id: this.id,
                                        ...this.ruleForm
                                    }, 'put').then((res) => {
                                        if (res.status) {
                                            this.$message.success('保存成功')
                                            if (num != 2) {
                                                this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                                                this.$router.push({path: '/activity/activityList'})
                                            } else {
                                                this.qrcode = 'data:image/png;base64,' + res.data.qrCode;
                                                this.id = res.data.id;
                                                this.previewQrCode = true;
                                            }
                                            // this.$axios(this.api.messge.xiugai, this.ruleForm.wxMesMb, 'put').then(res => {
                                            // })
                                        }
                                    })
                                } else {
                                    this.$axios(this.api.activity.updateByIdRelease, {
                                        actiState,
                                        id: this.id,
                                        ...this.ruleForm
                                    }, 'put').then((res) => {
                                        if (res.status) {
                                            this.$message.success('保存成功')
                                            if (num != 2) {
                                                this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                                                this.$router.push({path: '/activity/activityList'})
                                            } else {
                                                this.qrcode = 'data:image/png;base64,' + res.data.qrCode;
                                                this.id = res.data.id;
                                                this.previewQrCode = true;
                                            }
                                            // this.$axios(this.api.messge.xiugai, this.ruleForm.wxMesMb, 'put').then(res => {
                                            // })
                                        }
                                    })
                                }
                            }
                        } else {
                            return false;
                        }
                    });
                }
            },

            //通过，驳回请求接口
            afaffagffrfweg(num) {
                if (this.ruleForm.chargeType == 1 && this.ruleForm.price < 0.01){
                    this.$message.error('收费价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice < 0.01){
                    this.$message.error('促销价格不得低于0.01元')
                    return;
                }
                this.$axios(this.api.activity.modifyState, {
                    id: this.id,
                    actiState: num,
                }, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg);
                        this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                        this.$router.push({path: '/activity/activityList'})
                    }
                })
            },


            //0返回，1通过，2驳回，3发布，4提交，
            reject(num) {
                if (num == 2) {
                    this.afaffagffrfweg(0);
                } else if (num == 1) {
                    this.afaffagffrfweg(6);
                } else if (num == 3) {
                    this.afaffagffrfweg(99);
                } else if (num == 4) {
                    this.submitForm('ruleForm', 1)
                } else if (num == 0) {
                    this.$store.dispatch('tagsView/delView', {fullPath: this.$route.fullPath}).then()
                    this.$router.push({path: '/activity/activityList'})
                }
            },

            //0预览,1保存并预览 , 对前活动状态
            preview(num, actState) {
                if (this.ruleForm.chargeType == 1 && this.ruleForm.price < 0.01){
                    this.$message.error('收费价格不得低于0.01元')
                    return;
                }
                if (this.switchxuans == 1 && this.ruleForm.promotionPrice < 0.01){
                    this.$message.error('促销价格不得低于0.01元')
                    return;
                }
                if (num == 0) {
                    this.getPreviewMessages();
                    this.previewQrCode = true;
                } else {
                    if (actState) {
                        this.submitForm('ruleForm', 2, this.actiState)
                    } else {
                        this.submitForm('ruleForm', 2, 0)
                    }
                }
            },

            handleChangeTop() {
                if (!this.ruleForm.top) {
                    setTimeout(() => {
                        this.ruleForm.top = 1;
                    })
                }
            },

            //请求预览信息
            getPreviewMessages() {
                this.$axios(this.api.activity.getPreviewMessages, {id: this.id}, 'get').then(res => {
                    if (res.status) {
                        this.qrcode = 'data:image/png;base64,' + res.data;
                    }
                })
            },

            //关闭预览二维码
            previewQrCodeGuanbi() {
                this.previewQrCode = false;
            },
        }
    }
</script>

<style scoped>
</style>
